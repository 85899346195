import revive_payload_client_4sVQNw7RlN from "/var/www/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _01_auth_base_ibf96mRs9u from "/var/www/plugins/01.auth-base.ts";
import payload_client_yVLowv6hDl from "/var/www/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_YksOOA8Nkt from "/var/www/node_modules/nuxt-snackbar/dist/runtime/plugin.js";
import floating_vue_EIcJ7xiw0h from "/var/www/.nuxt/floating-vue.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/var/www/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/var/www/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import sweetalert2_21eHv6chX3 from "/var/www/plugins/sweetalert2.ts";
import vue_tel_input_jrQu9KYfqt from "/var/www/plugins/vue-tel-input.ts";
import vueGoogleMaps_8xmoAwKZvr from "/var/www/plugins/vueGoogleMaps.ts";
import vuedatepicker_oKNl9XPOyX from "/var/www/plugins/vuedatepicker.ts";
import plugin_client_QHzhVBB2Ll from "/var/www/.nuxt/nuxt-booster/plugin.client.js";
import _02_auth_prefetch_aZEAAX61bL from "/var/www/plugins/02.auth-prefetch.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _01_auth_base_ibf96mRs9u,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_YksOOA8Nkt,
  floating_vue_EIcJ7xiw0h,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  sweetalert2_21eHv6chX3,
  vue_tel_input_jrQu9KYfqt,
  vueGoogleMaps_8xmoAwKZvr,
  vuedatepicker_oKNl9XPOyX,
  plugin_client_QHzhVBB2Ll,
  _02_auth_prefetch_aZEAAX61bL
]