import { default as aboutBcrxsER0t1Meta } from "/var/www/pages/about.vue?macro=true";
import { default as _91slug_93PP5P6BvCOkMeta } from "/var/www/pages/activities/[slug].vue?macro=true";
import { default as allfWI4UEsOvCMeta } from "/var/www/pages/activities/all.vue?macro=true";
import { default as _91slug_93lyvoZXctkFMeta } from "/var/www/pages/activities/category/[slug].vue?macro=true";
import { default as indexUdEKEIZMp7Meta } from "/var/www/pages/activities/combo/index.vue?macro=true";
import { default as indexHH32f6B2kSMeta } from "/var/www/pages/activities/index.vue?macro=true";
import { default as indexdeVdIFl59xMeta } from "/var/www/pages/activities/others/index.vue?macro=true";
import { default as indexS8q5YpO8GAMeta } from "/var/www/pages/admin/addons-product/index.vue?macro=true";
import { default as indexwwe3nnnKz3Meta } from "/var/www/pages/admin/agent-special-price/index.vue?macro=true";
import { default as index8MOkQ4BVDpMeta } from "/var/www/pages/admin/agents/index.vue?macro=true";
import { default as editpYIns1MgzkMeta } from "/var/www/pages/admin/blog-categories/[slug]/edit.vue?macro=true";
import { default as createpYTEdCqQ7zMeta } from "/var/www/pages/admin/blog-categories/create.vue?macro=true";
import { default as indexs0U0vWNEwUMeta } from "/var/www/pages/admin/blog-categories/index.vue?macro=true";
import { default as editOK5uHEttEAMeta } from "/var/www/pages/admin/blogs/[slug]/edit.vue?macro=true";
import { default as imageseYoXUF3XZMMeta } from "/var/www/pages/admin/blogs/[slug]/images.vue?macro=true";
import { default as createMU2VbmjeGJMeta } from "/var/www/pages/admin/blogs/create.vue?macro=true";
import { default as indextf7btmQdkTMeta } from "/var/www/pages/admin/blogs/index.vue?macro=true";
import { default as editPls5ip3Hg0Meta } from "/var/www/pages/admin/categories/[slug]/edit.vue?macro=true";
import { default as imagesr14pbj3ug5Meta } from "/var/www/pages/admin/categories/[slug]/images.vue?macro=true";
import { default as creategaWl8qLFx5Meta } from "/var/www/pages/admin/categories/create.vue?macro=true";
import { default as indexa8yyOW2pHvMeta } from "/var/www/pages/admin/categories/index.vue?macro=true";
import { default as indexGF2QJSBxNAMeta } from "/var/www/pages/admin/contact/index.vue?macro=true";
import { default as forgot_45password_45verificationU213BBLnMsMeta } from "/var/www/pages/admin/forgot-password-verification.vue?macro=true";
import { default as forgot_45passwordK3LW1zSLtIMeta } from "/var/www/pages/admin/forgot-password.vue?macro=true";
import { default as inactivendLRyZsWh5Meta } from "/var/www/pages/admin/inactive.vue?macro=true";
import { default as indext2XeFHT7gaMeta } from "/var/www/pages/admin/index.vue?macro=true";
import { default as indexcDA0GeyQfZMeta } from "/var/www/pages/admin/orders/[slug]/index.vue?macro=true";
import { default as index1mPJqH53TgMeta } from "/var/www/pages/admin/orders/index.vue?macro=true";
import { default as calendarbw7wxGwv6KMeta } from "/var/www/pages/admin/products/[slug]/calendar.vue?macro=true";
import { default as edit3E9TypY6ZNMeta } from "/var/www/pages/admin/products/[slug]/edit.vue?macro=true";
import { default as imageshGK7TWdOxTMeta } from "/var/www/pages/admin/products/[slug]/images.vue?macro=true";
import { default as createMvsh6HFmlDMeta } from "/var/www/pages/admin/products/create.vue?macro=true";
import { default as indexTIKZK3pnqbMeta } from "/var/www/pages/admin/products/index.vue?macro=true";
import { default as settingsT2It53afEAMeta } from "/var/www/pages/admin/settings.vue?macro=true";
import { default as sign_45inmGC6sHpLqoMeta } from "/var/www/pages/admin/sign-in.vue?macro=true";
import { default as indexEojxNw0IBzMeta } from "/var/www/pages/admin/user-admin/index.vue?macro=true";
import { default as indexVfE7bhKhGoMeta } from "/var/www/pages/admin/users/index.vue?macro=true";
import { default as _91slug_93wXkIwjYSztMeta } from "/var/www/pages/admin/verified-email/[slug].vue?macro=true";
import { default as _91slug_93o8eMHqFD1JMeta } from "/var/www/pages/agent/email-verification/[slug].vue?macro=true";
import { default as forgot_45password_45verificationSnic2nbDT0Meta } from "/var/www/pages/agent/forgot-password-verification.vue?macro=true";
import { default as forgot_45passwordgcWxYCe2X3Meta } from "/var/www/pages/agent/forgot-password.vue?macro=true";
import { default as inactive1qXHE1Kf7KMeta } from "/var/www/pages/agent/inactive.vue?macro=true";
import { default as indexPKpOxUzrP7Meta } from "/var/www/pages/agent/index.vue?macro=true";
import { default as _91slug_93KXAzXNj09vMeta } from "/var/www/pages/agent/order/[slug].vue?macro=true";
import { default as profilem3OozPznHuMeta } from "/var/www/pages/agent/profile.vue?macro=true";
import { default as security224g0bzpPjMeta } from "/var/www/pages/agent/security.vue?macro=true";
import { default as sign_45inXdB6AebFsDMeta } from "/var/www/pages/agent/sign-in.vue?macro=true";
import { default as sign_45upEtMUsf66eYMeta } from "/var/www/pages/agent/sign-up.vue?macro=true";
import { default as _91slug_93zm2TSqFizIMeta } from "/var/www/pages/blog/[slug].vue?macro=true";
import { default as indexMFEbtQohiUMeta } from "/var/www/pages/blog/index.vue?macro=true";
import { default as contact_45detailA2pE2i6X0IMeta } from "/var/www/pages/booking/contact-detail.vue?macro=true";
import { default as edit_45contact_45detailBCcgG5ieB9Meta } from "/var/www/pages/booking/edit-contact-detail.vue?macro=true";
import { default as index0GQpwrWFiQMeta } from "/var/www/pages/booking/index.vue?macro=true";
import { default as validationpJIralUNi2Meta } from "/var/www/pages/booking/validation.vue?macro=true";
import { default as indextPwNvp0K2bMeta } from "/var/www/pages/cart/index.vue?macro=true";
import { default as contact_45successamDtiFll6NMeta } from "/var/www/pages/contact-success.vue?macro=true";
import { default as contact_45us5N5Xh1U017Meta } from "/var/www/pages/contact-us.vue?macro=true";
import { default as _91slug_93Q4FU3ItovaMeta } from "/var/www/pages/email-verification/[slug].vue?macro=true";
import { default as forgot_45password_45verificationIUSxCQBJcwMeta } from "/var/www/pages/forgot-password-verification.vue?macro=true";
import { default as forgot_45passwordbaSN0DDMymMeta } from "/var/www/pages/forgot-password.vue?macro=true";
import { default as indexTNebhwOASsMeta } from "/var/www/pages/index.vue?macro=true";
import { default as _91slug_93fuVO9bVimkMeta } from "/var/www/pages/order/[slug].vue?macro=true";
import { default as payment_45failedfKqGoMUzF6Meta } from "/var/www/pages/payment-failed.vue?macro=true";
import { default as payment_45successfullUubglztp3BMeta } from "/var/www/pages/payment-successfull.vue?macro=true";
import { default as privacy_45policy9QsFv2Ub0jMeta } from "/var/www/pages/privacy-policy.vue?macro=true";
import { default as sign_45inL76ohyKHp0Meta } from "/var/www/pages/sign-in.vue?macro=true";
import { default as sign_45upwucxvx4TFpMeta } from "/var/www/pages/sign-up.vue?macro=true";
import { default as terms_45and_45conditions48abWHWlBjMeta } from "/var/www/pages/terms-and-conditions.vue?macro=true";
import { default as inactiveP3F17GflYzMeta } from "/var/www/pages/user/inactive.vue?macro=true";
import { default as indexNE5uRngM4HMeta } from "/var/www/pages/user/index.vue?macro=true";
import { default as _91slug_93UytQ9FjFLAMeta } from "/var/www/pages/user/order/[slug].vue?macro=true";
import { default as profileo7vePGf65uMeta } from "/var/www/pages/user/profile.vue?macro=true";
import { default as securityRUMdDn4N7vMeta } from "/var/www/pages/user/security.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/var/www/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "activities-slug",
    path: "/activities/:slug()",
    meta: _91slug_93PP5P6BvCOkMeta || {},
    component: () => import("/var/www/pages/activities/[slug].vue").then(m => m.default || m)
  },
  {
    name: "activities-all",
    path: "/activities/all",
    component: () => import("/var/www/pages/activities/all.vue").then(m => m.default || m)
  },
  {
    name: "activities-category-slug",
    path: "/activities/category/:slug()",
    meta: _91slug_93lyvoZXctkFMeta || {},
    component: () => import("/var/www/pages/activities/category/[slug].vue").then(m => m.default || m)
  },
  {
    name: "activities-combo",
    path: "/activities/combo",
    component: () => import("/var/www/pages/activities/combo/index.vue").then(m => m.default || m)
  },
  {
    name: "activities",
    path: "/activities",
    component: () => import("/var/www/pages/activities/index.vue").then(m => m.default || m)
  },
  {
    name: "activities-others",
    path: "/activities/others",
    component: () => import("/var/www/pages/activities/others/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-addons-product",
    path: "/admin/addons-product",
    meta: indexS8q5YpO8GAMeta || {},
    component: () => import("/var/www/pages/admin/addons-product/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-agent-special-price",
    path: "/admin/agent-special-price",
    meta: indexwwe3nnnKz3Meta || {},
    component: () => import("/var/www/pages/admin/agent-special-price/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-agents",
    path: "/admin/agents",
    meta: index8MOkQ4BVDpMeta || {},
    component: () => import("/var/www/pages/admin/agents/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-blog-categories-slug-edit",
    path: "/admin/blog-categories/:slug()/edit",
    meta: editpYIns1MgzkMeta || {},
    component: () => import("/var/www/pages/admin/blog-categories/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-blog-categories-create",
    path: "/admin/blog-categories/create",
    meta: createpYTEdCqQ7zMeta || {},
    component: () => import("/var/www/pages/admin/blog-categories/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-blog-categories",
    path: "/admin/blog-categories",
    meta: indexs0U0vWNEwUMeta || {},
    component: () => import("/var/www/pages/admin/blog-categories/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-blogs-slug-edit",
    path: "/admin/blogs/:slug()/edit",
    meta: editOK5uHEttEAMeta || {},
    component: () => import("/var/www/pages/admin/blogs/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-blogs-slug-images",
    path: "/admin/blogs/:slug()/images",
    meta: imageseYoXUF3XZMMeta || {},
    component: () => import("/var/www/pages/admin/blogs/[slug]/images.vue").then(m => m.default || m)
  },
  {
    name: "admin-blogs-create",
    path: "/admin/blogs/create",
    meta: createMU2VbmjeGJMeta || {},
    component: () => import("/var/www/pages/admin/blogs/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-blogs",
    path: "/admin/blogs",
    meta: indextf7btmQdkTMeta || {},
    component: () => import("/var/www/pages/admin/blogs/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-categories-slug-edit",
    path: "/admin/categories/:slug()/edit",
    meta: editPls5ip3Hg0Meta || {},
    component: () => import("/var/www/pages/admin/categories/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-categories-slug-images",
    path: "/admin/categories/:slug()/images",
    meta: imagesr14pbj3ug5Meta || {},
    component: () => import("/var/www/pages/admin/categories/[slug]/images.vue").then(m => m.default || m)
  },
  {
    name: "admin-categories-create",
    path: "/admin/categories/create",
    meta: creategaWl8qLFx5Meta || {},
    component: () => import("/var/www/pages/admin/categories/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-categories",
    path: "/admin/categories",
    meta: indexa8yyOW2pHvMeta || {},
    component: () => import("/var/www/pages/admin/categories/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-contact",
    path: "/admin/contact",
    meta: indexGF2QJSBxNAMeta || {},
    component: () => import("/var/www/pages/admin/contact/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-forgot-password-verification",
    path: "/admin/forgot-password-verification",
    meta: forgot_45password_45verificationU213BBLnMsMeta || {},
    component: () => import("/var/www/pages/admin/forgot-password-verification.vue").then(m => m.default || m)
  },
  {
    name: "admin-forgot-password",
    path: "/admin/forgot-password",
    meta: forgot_45passwordK3LW1zSLtIMeta || {},
    component: () => import("/var/www/pages/admin/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "admin-inactive",
    path: "/admin/inactive",
    meta: inactivendLRyZsWh5Meta || {},
    component: () => import("/var/www/pages/admin/inactive.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: indext2XeFHT7gaMeta || {},
    component: () => import("/var/www/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-orders-slug",
    path: "/admin/orders/:slug()",
    meta: indexcDA0GeyQfZMeta || {},
    component: () => import("/var/www/pages/admin/orders/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-orders",
    path: "/admin/orders",
    meta: index1mPJqH53TgMeta || {},
    component: () => import("/var/www/pages/admin/orders/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-products-slug-calendar",
    path: "/admin/products/:slug()/calendar",
    meta: calendarbw7wxGwv6KMeta || {},
    component: () => import("/var/www/pages/admin/products/[slug]/calendar.vue").then(m => m.default || m)
  },
  {
    name: "admin-products-slug-edit",
    path: "/admin/products/:slug()/edit",
    meta: edit3E9TypY6ZNMeta || {},
    component: () => import("/var/www/pages/admin/products/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: "admin-products-slug-images",
    path: "/admin/products/:slug()/images",
    meta: imageshGK7TWdOxTMeta || {},
    component: () => import("/var/www/pages/admin/products/[slug]/images.vue").then(m => m.default || m)
  },
  {
    name: "admin-products-create",
    path: "/admin/products/create",
    meta: createMvsh6HFmlDMeta || {},
    component: () => import("/var/www/pages/admin/products/create.vue").then(m => m.default || m)
  },
  {
    name: "admin-products",
    path: "/admin/products",
    meta: indexTIKZK3pnqbMeta || {},
    component: () => import("/var/www/pages/admin/products/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-settings",
    path: "/admin/settings",
    meta: settingsT2It53afEAMeta || {},
    component: () => import("/var/www/pages/admin/settings.vue").then(m => m.default || m)
  },
  {
    name: "admin-sign-in",
    path: "/admin/sign-in",
    meta: sign_45inmGC6sHpLqoMeta || {},
    component: () => import("/var/www/pages/admin/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "admin-user-admin",
    path: "/admin/user-admin",
    meta: indexEojxNw0IBzMeta || {},
    component: () => import("/var/www/pages/admin/user-admin/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "/admin/users",
    meta: indexVfE7bhKhGoMeta || {},
    component: () => import("/var/www/pages/admin/users/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-verified-email-slug",
    path: "/admin/verified-email/:slug()",
    meta: _91slug_93wXkIwjYSztMeta || {},
    component: () => import("/var/www/pages/admin/verified-email/[slug].vue").then(m => m.default || m)
  },
  {
    name: "agent-email-verification-slug",
    path: "/agent/email-verification/:slug()",
    meta: _91slug_93o8eMHqFD1JMeta || {},
    component: () => import("/var/www/pages/agent/email-verification/[slug].vue").then(m => m.default || m)
  },
  {
    name: "agent-forgot-password-verification",
    path: "/agent/forgot-password-verification",
    meta: forgot_45password_45verificationSnic2nbDT0Meta || {},
    component: () => import("/var/www/pages/agent/forgot-password-verification.vue").then(m => m.default || m)
  },
  {
    name: "agent-forgot-password",
    path: "/agent/forgot-password",
    meta: forgot_45passwordgcWxYCe2X3Meta || {},
    component: () => import("/var/www/pages/agent/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "agent-inactive",
    path: "/agent/inactive",
    meta: inactive1qXHE1Kf7KMeta || {},
    component: () => import("/var/www/pages/agent/inactive.vue").then(m => m.default || m)
  },
  {
    name: "agent",
    path: "/agent",
    meta: indexPKpOxUzrP7Meta || {},
    component: () => import("/var/www/pages/agent/index.vue").then(m => m.default || m)
  },
  {
    name: "agent-order-slug",
    path: "/agent/order/:slug()",
    meta: _91slug_93KXAzXNj09vMeta || {},
    component: () => import("/var/www/pages/agent/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "agent-profile",
    path: "/agent/profile",
    meta: profilem3OozPznHuMeta || {},
    component: () => import("/var/www/pages/agent/profile.vue").then(m => m.default || m)
  },
  {
    name: "agent-security",
    path: "/agent/security",
    meta: security224g0bzpPjMeta || {},
    component: () => import("/var/www/pages/agent/security.vue").then(m => m.default || m)
  },
  {
    name: "agent-sign-in",
    path: "/agent/sign-in",
    meta: sign_45inXdB6AebFsDMeta || {},
    component: () => import("/var/www/pages/agent/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "agent-sign-up",
    path: "/agent/sign-up",
    meta: sign_45upEtMUsf66eYMeta || {},
    component: () => import("/var/www/pages/agent/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93zm2TSqFizIMeta || {},
    component: () => import("/var/www/pages/blog/[slug].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    component: () => import("/var/www/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-contact-detail",
    path: "/booking/contact-detail",
    meta: contact_45detailA2pE2i6X0IMeta || {},
    component: () => import("/var/www/pages/booking/contact-detail.vue").then(m => m.default || m)
  },
  {
    name: "booking-edit-contact-detail",
    path: "/booking/edit-contact-detail",
    meta: edit_45contact_45detailBCcgG5ieB9Meta || {},
    component: () => import("/var/www/pages/booking/edit-contact-detail.vue").then(m => m.default || m)
  },
  {
    name: "booking",
    path: "/booking",
    meta: index0GQpwrWFiQMeta || {},
    component: () => import("/var/www/pages/booking/index.vue").then(m => m.default || m)
  },
  {
    name: "booking-validation",
    path: "/booking/validation",
    meta: validationpJIralUNi2Meta || {},
    component: () => import("/var/www/pages/booking/validation.vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    meta: indextPwNvp0K2bMeta || {},
    component: () => import("/var/www/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "contact-success",
    path: "/contact-success",
    meta: contact_45successamDtiFll6NMeta || {},
    component: () => import("/var/www/pages/contact-success.vue").then(m => m.default || m)
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/var/www/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: "email-verification-slug",
    path: "/email-verification/:slug()",
    meta: _91slug_93Q4FU3ItovaMeta || {},
    component: () => import("/var/www/pages/email-verification/[slug].vue").then(m => m.default || m)
  },
  {
    name: "forgot-password-verification",
    path: "/forgot-password-verification",
    meta: forgot_45password_45verificationIUSxCQBJcwMeta || {},
    component: () => import("/var/www/pages/forgot-password-verification.vue").then(m => m.default || m)
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordbaSN0DDMymMeta || {},
    component: () => import("/var/www/pages/forgot-password.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "order-slug",
    path: "/order/:slug()",
    meta: _91slug_93fuVO9bVimkMeta || {},
    component: () => import("/var/www/pages/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "payment-failed",
    path: "/payment-failed",
    meta: payment_45failedfKqGoMUzF6Meta || {},
    component: () => import("/var/www/pages/payment-failed.vue").then(m => m.default || m)
  },
  {
    name: "payment-successfull",
    path: "/payment-successfull",
    meta: payment_45successfullUubglztp3BMeta || {},
    component: () => import("/var/www/pages/payment-successfull.vue").then(m => m.default || m)
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    meta: privacy_45policy9QsFv2Ub0jMeta || {},
    component: () => import("/var/www/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "sign-in",
    path: "/sign-in",
    meta: sign_45inL76ohyKHp0Meta || {},
    component: () => import("/var/www/pages/sign-in.vue").then(m => m.default || m)
  },
  {
    name: "sign-up",
    path: "/sign-up",
    meta: sign_45upwucxvx4TFpMeta || {},
    component: () => import("/var/www/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: "terms-and-conditions",
    path: "/terms-and-conditions",
    meta: terms_45and_45conditions48abWHWlBjMeta || {},
    component: () => import("/var/www/pages/terms-and-conditions.vue").then(m => m.default || m)
  },
  {
    name: "user-inactive",
    path: "/user/inactive",
    meta: inactiveP3F17GflYzMeta || {},
    component: () => import("/var/www/pages/user/inactive.vue").then(m => m.default || m)
  },
  {
    name: "user",
    path: "/user",
    meta: indexNE5uRngM4HMeta || {},
    component: () => import("/var/www/pages/user/index.vue").then(m => m.default || m)
  },
  {
    name: "user-order-slug",
    path: "/user/order/:slug()",
    meta: _91slug_93UytQ9FjFLAMeta || {},
    component: () => import("/var/www/pages/user/order/[slug].vue").then(m => m.default || m)
  },
  {
    name: "user-profile",
    path: "/user/profile",
    meta: profileo7vePGf65uMeta || {},
    component: () => import("/var/www/pages/user/profile.vue").then(m => m.default || m)
  },
  {
    name: "user-security",
    path: "/user/security",
    meta: securityRUMdDn4N7vMeta || {},
    component: () => import("/var/www/pages/user/security.vue").then(m => m.default || m)
  }
]